<template>
    <v-layout>
        <v-snackbar
                v-model="notification"
                :color="'success'"
                :multi-line="false"
                :timeout="3000"
                :vertical="false"
                :top="true"
        >
            {{ notification_message }}
            <v-btn
                    dark
                    text
                    @click="notification = false"
            >
                Close
            </v-btn>
        </v-snackbar>

        <v-flex xs12>
            <v-card class="pa-5">
                <v-flex xs12>
                    <div class="title-header">
                        <h2>
                            Checkout <sup>({{ items.length }})</sup>
                        </h2>
                    </div>

                    <v-simple-table height="300px">
                        <thead>
                        <tr>
                            <th>
                                Image
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                FOB
                            </th>
                            <th>
                                Target FOB
                            </th>
                            <th>
                                QTY
                            </th>
                            <th>
                                PC's Per MC
                            </th>
                            <th>
                                Total MC
                            </th>
                            <th>
                                Totals
                            </th>
                            <th>
                                Errors
                            </th>
                            <th>
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in items" :key="index"
                            class="white--text"
                            v-bind:class="{ success: !item.errors.length, red: item.errors.length }"
                        >
                            <td @click="edit_cart_item(item)">
                                <v-img
                                        class="white--text"
                                        height="55"
                                        v-if="item.product.album && item.product.album.files.length"
                                        max-width="100"
                                        :src="item.product.album.files[0].url"
                                >
                                </v-img>

                            </td>
                            <td @click="edit_cart_item(item)">
                                {{ item.product.name }}
                            </td>
                            <td @click="edit_cart_item(item)">
                                {{ item.fob_price | currency }}
                            </td>
                            <td @click="edit_cart_item(item)">
                                {{ item.customer_target_price | currency }}
                            </td>
                            <td @click="edit_cart_item(item)" class="text-center">
                                {{ item.qty }}
                            </td>
                            <td @click="edit_cart_item(item)" class="text-center">
                                {{ item.master_carton_qty }}
                            </td>
                            <td @click="edit_cart_item(item)" class="text-center">
                                <span v-if="item.master_carton_qty > 0">
                                    {{ item.qty / item.master_carton_qty }}
                                </span>

                            </td>
                            <td @click="edit_cart_item(item)">
                                {{ (item.qty * item.fob_price) | currency }}
                            </td>
                            <td @click="edit_cart_item(item)">
                                {{ item.errors.length }}
                            </td>
                            <td>
                                <v-btn
                                        color="white"
                                        text
                                        @click="deleteCartItem(item)"
                                >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>

                    <v-dialog v-model="confirm_dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="confirm_dialog = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>Cart Item Settings</v-toolbar-title>
                                <div class="flex-grow-1"></div>
                                <v-toolbar-items>
                                    <v-btn dark text @click="updateCartItem(selected_item)">Save</v-btn>
                                </v-toolbar-items>
                            </v-toolbar>

                            <v-alert
                                    v-if="selected_item.errors.length"
                                    :value="true"
                                    type="error"
                            >
                            <span v-for="error in selected_item.errors">
                                {{ error }} <br>
                            </span>
                            </v-alert>
                            <v-alert
                                    v-if="(selected_item.qty / selected_item.master_carton_qty) % 1 !== 0"
                                    type="error"
                            >
                                ERROR: Master ctn qty and qty of items do not produce a whole number
                            </v-alert>
                            <v-form v-model="is_valid">
                                <v-container>
                                    <v-layout row>
                                        <v-flex
                                                xs12
                                                md4
                                        >
                                            <v-select
                                                    @change="change_selected_remark"
                                                    v-model="selected_item.packing_alt"
                                                    :items="packing_items"
                                                    label="Packing"
                                                    required
                                            ></v-select>
                                            <v-text-field
                                                    @keyup="change_selected_remark"
                                                    @change="change_selected_remark"
                                                    v-if="selected_item.packing_alt === 'Other'"
                                                    v-model="selected_item.packing"
                                                    label="Packing"
                                                    required=""></v-text-field>
                                        </v-flex>
                                        <v-flex
                                                xs12
                                                md4
                                        >
                                            <v-select
                                                    @change="change_selected_remark"
                                                    v-model="selected_item.artwork_alt"
                                                    :items="artwork_items"
                                                    label="Artwork"
                                                    required
                                            ></v-select>
                                            <v-text-field
                                                    @keyup="change_selected_remark"
                                                    @change="change_selected_remark"
                                                    v-if="selected_item.artwork_alt === 'Other'"
                                                    v-model="selected_item.artwork"
                                                    label="Artwork"
                                                    required=""></v-text-field>
                                        </v-flex>
                                        <v-flex
                                                xs12
                                                md4
                                        >
                                            <v-select
                                                    @change="change_selected_remark"
                                                    v-model="selected_item.display_alt"
                                                    :items="display_items"
                                                    label="Display"
                                                    required
                                            ></v-select>
                                            <v-text-field
                                                    @keyup="change_selected_remark"
                                                    @change="change_selected_remark"
                                                    v-if="selected_item.display_alt === 'Other'"
                                                    v-model="selected_item.display"
                                                    label="Artwork"
                                                    required=""></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex
                                                xs12
                                                md4
                                        >
                                            <v-text-field
                                                    @keyup="change_selected_remark"
                                                    v-model="selected_item.master_carton_qty"
                                                    label="PC's Master Carton QTY"
                                                    required=""></v-text-field>

                                        </v-flex>
                                        <v-flex
                                                xs12
                                                md4
                                        >
                                            <strong>
                                                Total Master Carton QTY
                                            </strong>
                                            <br>
                                            {{ selected_item.qty / selected_item.master_carton_qty }}

                                        </v-flex>
                                        <v-flex
                                                xs12
                                                md4
                                        >
                                            <v-text-field
                                                    @keyup="change_selected_remark"
                                                    v-model="selected_item.inner_carton_qty"
                                                    label="Inner Carton QTY"
                                                    required=""></v-text-field>
                                        </v-flex>
                                        <v-flex
                                                xs12
                                                md4
                                        >

                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex
                                                xs12
                                                md4
                                        >
                                            <v-text-field
                                                    @keyup="change_selected_remark"
                                                    @change="change_selected_remark"
                                                    v-model="selected_item.qty"
                                                    label="Item QTY"
                                                    required=""></v-text-field>
                                        </v-flex>
                                        <v-flex
                                                xs12
                                                md4
                                        >
                                            <v-text-field
                                                    v-model="selected_item.fob_price"
                                                    label="Item Price"
                                                    :disabled="true"
                                                    required=""></v-text-field>
                                        </v-flex>
                                        <v-flex
                                                xs12
                                                md4
                                        >
                                            <v-text-field
                                                    v-model="selected_item.customer_target_price"
                                                    label="Target Price"
                                                    type="number"
                                                    required=""></v-text-field>
                                        </v-flex>
                                        <v-flex
                                                xs12
                                                md4
                                        >
                                            <v-select
                                                    @change="change_selected_remark"
                                                    v-model="selected_item.battery_included"
                                                    :items="battery_items"
                                                    label="battery Included"
                                                    required
                                            ></v-select>
                                        </v-flex>
                                        <v-flex
                                                xs12
                                                md4
                                        >
                                            {{ (selected_item.qty * selected_item.fob_price) | currency }}
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-textarea
                                            label="Remarks"
                                            v-model="selected_item.remarks"
                                            class=""></v-textarea>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-form>
                            <v-card-actions>
                                <v-btn
                                        color="blue-grey darken-4"
                                        text
                                        @click="confirm_dialog = false"
                                >
                                    Cancel
                                </v-btn>

                                <v-spacer></v-spacer>
                                <v-btn
                                        color="success"
                                        text
                                        @click="deleteCartItem(selected_item)"
                                >
                                    <v-icon>mdi-delete</v-icon>
                                    Remove Item
                                </v-btn>


                                <v-btn
                                        v-show="(selected_item.qty / selected_item.master_carton_qty) % 1 === 0"
                                        color="success"
                                        text
                                        @click="updateCartItem(selected_item)"
                                >
                                    Update Item
                                </v-btn>
                            </v-card-actions>

                        </v-card>
                    </v-dialog>

                    <v-btn v-on:click="quick_quote()" color="blue-grey darken-4" class="uk-align-right" v-if="items.length"
                    >
                        <v-icon>
                            mdi-download
                        </v-icon> Quick Quote
                    </v-btn>

                    <v-btn v-on:click="checkout()" color="blue-grey darken-4" class="uk-align-right" v-if="items.length"
                           >
                        Create QT
                    </v-btn>

                    <v-btn @click="wish_list_dialog = true"
                           color="blue-grey darken-4"
                           class="uk-align-right" v-if="items.length">
                        Save To WishList
                    </v-btn>

                </v-flex>
            </v-card>

        </v-flex>

        <v-dialog
                width="500"
                v-model="wish_list_dialog">
            <v-card class="pa-2">
                <v-card-title
                        class="headline grey lighten-2"
                        primary-title
                >
                    Make a WishList
                </v-card-title>
                <v-card-text class="mt-5">
                    This will add all current cart items to a wishlist, wishlist items can be removed,
                    or deleted at any time. This will allow you to save quotes to be reused in the future
                    there will also be an update button which will auto update priced to the current day.
                </v-card-text>
                <v-divider></v-divider>
                <v-text-field
                        v-model="wishlist.name"
                        :counter="200"
                        label="Name"
                        required
                        :error-messages="checkWishlistName"
                        @input="$v.wishlist.name.$touch()"
                        @blur="$v.wishlist.name.$touch()"
                ></v-text-field>
                <v-divider></v-divider>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn
                            color="primary"
                            text
                            @click="save_wish_list(wishlist)"
                    >
                        Save WishList
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-layout>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required, maxLength, minLength, email} from 'vuelidate/lib/validators'
    import {saveAs} from 'file-saver'
    import {gen_quick_quote} from '@/plugins/excel_pi_po_generator';

    export default {
        name: "Cart",
        mixins: [validationMixin],

        validations: {
            wishlist: {
                name: {
                    required,
                    maxLength: maxLength(200),
                    minLength: minLength(2)
                }
            }
        },
        data: () => ({
            title: "Cart",
            items: [],
            user: {},
            notification_message: '',
            notification: false,
            is_valid: false,
            has_errors: true,
            wish_list_dialog: false,
            pi_number:'',
            wishlist: {
                name: ''
            },
            packing_items: [
                'Color Box',
                'Tie Card',
                'Blister Card',
                'Clam Shell',
                'Bulk',
                'Shrink Pack',
                'Window Box',
                'Brown Box',
                'White Box',
                'PVC Box',
                'OPP Bag',
                'Other',
            ],
            display_items: [
                'Floor Display',
                'PDQ',
                'Pallet Display',
                'CLIPSTRIP',
                'Other',
            ],
            artwork_items: [
                'OEM Packing',
                'Factory Packing',
                'Stick Poster',
                'Other',
            ],
            battery_items: [
                'NO',
                'YES'
            ],
            initial_load: true,
            headers: [
                {
                    text: 'Image',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Name',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'FOB',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Target FOB',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'QTY',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Totals',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Errors',
                    align: 'center',
                    sortable: false,
                }
            ],
            confirm_dialog: false,
            selected_item: {
                errors: [],
                product: {}
            },
            pi_type: '',
            url_type: '',

        }),
        created() {
            this.user = this.$store.getters.user;
            this.get_cart_items();
        },
        computed: {
            checkWishlistName() {
                const errors = [];
                if (!this.$v.wishlist.name.$dirty) return errors;
                !this.$v.wishlist.name.maxLength && errors.push('wishlist must be at most 200 characters long');
                !this.$v.wishlist.name.minLength && errors.push('wishlist must be at least 2 characters long');
                !this.$v.wishlist.name.required && errors.push('wishlist is required.');
                return errors;
            },
        },
        methods: {
            async quick_quote() {
                let number = 'QUICKQUOTE'+ Math.ceil(Math.random()*9283);
                let sheet_buffer = await gen_quick_quote(this, this.items);
                await saveAs(new Blob([sheet_buffer]), number + ".xlsx");
            },
            save_wish_list(wishlist) {
                this.$v.wishlist.$touch();
                if (this.$v.wishlist.$invalid) {
                    this.notification = true;
                    this.notification_type = 'red darken-2';
                    this.notification_message = 'A name must be provided to create a wishlist.';
                    return false;
                }

                this.$hpost('/store/wishlist/', wishlist).then((response) => {
                    console.log(response);
                    this.notification = true;
                    this.notification_message = 'Successfully Saved Wishlist';
                    this.items = [];
                    this.wishlist.name = '';
                    this.wish_list_dialog = false;

                });
            },
            edit_cart_item(item) {
                this.selected_item = item;
                this.confirm_dialog = true;
            },
            get_cart_items() {
                this.$hget('/store/cart-items')
                    .then(response => {
                        console.log(response);
                        this.items = response.data.results;
                        this.initial_load = true;
                        this.validate_data(this.items)
                    });
            },
            deleteCartItem(item, index) {
                if(confirm('Are you sure you want to delete '+item.product.name))
                {
                    this.$hdelete('/store/cart-items/' + item.id)
                        .then(response => {
                            this.get_cart_items();
                        });
                }
            },
            updateCartItem(item) {
                console.log(item);
                let customer_target_price_percentage_difference = 0;
                let customer_target_price = item.customer_target_price;
                let v1 = 0;
                let v2 = 0;
                if(!customer_target_price || customer_target_price === 0)
                {
                    customer_target_price = item.fob_price;
                    item.customer_target_price = item.fob_price;
                }

                if(item.fob_price > customer_target_price)
                {
                    v1 = customer_target_price;
                    v2 = item.fob_price;
                } else {
                    v2 = customer_target_price;
                    v1 = item.fob_price;
                }
                customer_target_price_percentage_difference = ((v2 - v1) / Math.abs(v1)) * 100;
                let update_data = {
                    "qty": item.qty,
                    "fob_price": item.fob_price,
                    "target_price": item.target_price,
                    "remarks": item.remarks,
                    "packing": item.packing,
                    "display": item.display,
                    "artwork": item.artwork,
                    "master_carton_qty": item.master_carton_qty,
                    "inner_carton_qty": item.inner_carton_qty,
                    "battery_included": item.battery_included,
                    "customer_target_price": item.customer_target_price,
                    "customer_target_price_percentage_difference": customer_target_price_percentage_difference,
                    "user": this.user.id,
                    "product": item.product.id
                };

                this.$http.put('/store/cart-items/' + item.id, update_data)
                    .then(response => {
                        this.notification = true;
                        this.notification_message = 'Successfully Updated Cart Item';
                        console.log(response);
                        this.confirm_dialog = false;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
            checkout() {
                let errors = false;
                this.items.forEach(function(item){
                    if(item.errors.length)
                    {
                        errors = true;
                    }
                });

                if(errors === true)
                {
                    this.$notify({
                        title: 'Error:',
                        text: 'Errors found in items, please click the item and ensure all values are entered',
                        group: 'notifications',
                        position: 'top right'
                    });
                    return false;
                }
                let profile = this.user.profile_user;
                if (!profile) {
                    this.notification = true;
                    this.notification_message = 'Error: Your user account does not have an associated profile, please contact administration.';
                    return false;
                }

                console.log(profile);

                if (!profile.department) {
                    this.notification = true;
                    this.notification_message = 'Error: You have not selected a department, please login and select a department that you are currently buying for';

                    return false;
                }


                this.$hpost('/store/checkout', {
                    data: this.items
                })
                    .then((response) => {
                        console.log("checkout complete");
                        console.log(response);
                        this.pi_number = response.data.pi_number;
                        console.log(this.pi_number);
                        this.items = [];
                        this.notification = true;
                        this.notification_message = 'Successfully Checked Out, please check the orders section in C-Panel';
                    });
            },
            change_selected_remark() {
                this.validate_data(this.items)
            },
            validate_data(items) {
                let is_valid = true;
                let initial_load = this.initial_load;

                let i = 0;
                items.forEach(function (value, key) {
                    items[i].errors = [];

                    if (initial_load === true) {
                        items[i].artwork_alt = 'Other';
                        items[i].display_alt = 'Other';
                        items[i].packing_alt = 'Other';
                        console.log(items[i].packing_alt);
                    }

                    if (items[i].packing_alt !== 'Other' && items[i].packing_alt) {
                        items[i].packing = items[i].packing_alt;
                    }
                    if (!items[i].packing || items[i].packing === '') {
                        items[i].errors.push('Requires Packing');
                        is_valid = false;
                    }
                    if (!items[i].display || items[i].display === '') {
                        items[i].errors.push('Requires Display');
                        is_valid = false;
                    }
                    if (items[i].display_alt !== 'Other' && items[i].display_alt) {
                        items[i].display = items[i].display_alt;
                    }

                    if (!items[i].artwork || items[i].artwork === '') {
                        items[i].errors.push('Requires Artwork');
                        is_valid = false;
                    }
                    if (items[i].artwork_alt !== 'Other' && items[i].artwork_alt) {
                        items[i].artwork = items[i].artwork_alt;
                    }


                    if (!parseInt(items[i].master_carton_qty) || parseInt(items[i].master_carton_qty) < 1) {
                        items[i].errors.push('Requires Master Carton QTY');
                        is_valid = false;
                    }


                    console.log(items[i].errors);
                    i++;
                });

                console.log(items);
                this.items = items;
                this.is_valid = is_valid;
                this.initial_load = false;
            }
        }
    }
</script>

<style scoped>

</style>
